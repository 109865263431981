<template>
  <div>
    <div class="contact">
      <div class="contactimg">
        <img :src="bannerimg" alt="">
        <!-- <div class="imgtit">
          <p class="big">寻求合作，共赢未来</p>
          <p class="sm">打造最酷虚拟现实内容和互动体验</p>
        </div> -->
      </div>
      <div class="contactinfo">
        <div class="product_title">
          <div class="product_tit">
            <div class="tit1">联系我们</div>
            <div class="tit2"></div>
          </div>
        </div>
        <ul>
          <li>
            <div class="top">
              <img src="@/assets/images/09联系我们/企业名称.png" alt="">
            </div>
            <div class="mid">
              <div class="midtitle">
                <div class="midtit1">企业名称</div>
                <div class="midtit2"></div>
              </div>
            </div>
            <div class="bot">
              <p>南京梦宇三维技术有限公司</p>
              <p style="margin:10px 0px">Nanjing DreamUniverssal 3D Technologies Inc.</p>
            </div>
          </li>
          <li @click="dialogVisible=true">
            <div class="top">
              <img src="@/assets/images/09联系我们/公司地址.png" alt="">
            </div>
            <div class="mid">
              <div class="midtitle">
                <div class="midtit1" >公司地址</div>
                <div class="midtit2"></div>
              </div>
            </div>
            <div class="bot">
              <p style="width:250px">南京市雨花台区宁双路19号软件谷云密城B幢六楼</p>

            </div>
          </li>
          <li>
            <div class="top">
              <img src="@/assets/images/09联系我们/联系电话.png" alt="">
            </div>
            <div class="mid">
              <div class="midtitle">
                <div class="midtit1">联系电话</div>
                <div class="midtit2"></div>
              </div>
            </div>
            <div class="bot">
              <p>025-86386606</p>

            </div>
          </li>
          <li>
            <div class="top">
              <img src="@/assets/images/09联系我们/邮箱.png" alt="">
            </div>
            <div class="mid">
              <div class="midtitle">
                <div class="midtit1">邮箱</div>
                <div class="midtit2"></div>
              </div>
            </div>
            <div class="bot">
              <p>fang.tong@mengyu-3d.com</p>

            </div>
          </li>
        </ul>
      </div>
    </div>
    <el-dialog  :visible.sync="dialogVisible" width="1070" >
      <div class="company-map">
      <iframe width="1070" height="478" frameborder='0' scrolling='no' marginheight='0' marginwidth='0' src="https://surl.amap.com/bkrHE1Z1g4Tj"></iframe>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getbannerdetail } from "@/http/api/user.js";
export default {
  data() {
    return {
      bannerimg: "",
      content: "",
      addressimg: "",
      dialogVisible: false,
    };
  },
  mounted() {
    this.getcompany();
  },
  created() {},
  methods: {
    //获取公司简介
    async getcompany() {
      let params = {
        barId: 11,
      };
      const data = await getbannerdetail(params);
      this.bannerimg = "http://36.152.65.166:10008" + data.data.image;
      // this.content = data.data.children[0].content;
      // this.addressimg =
      //   "http://36.152.65.166:10008" + data.data.children[0].image;
      // console.log(data.data);
    },
  },
};
</script>

<style>
</style>
<style lang="scss" scoped>
  .company-map {
    width: 98%;
    display: flex;
    padding: 10px;
    justify-content: center;
  }
.contact {
  height: 1316px;
  overflow: hidden;
  .contactimg {
    width: 100%;
    height: 410px;
    display: flex;
    align-items: center;
    justify-content: center;

    // background: url("../assets/images/09联系我们/联系我们.jpg") no-repeat;
    background-size: 100% 100%;
    .imgtit {
      text-align: center;
      color: #fff;
      .big {
        font-size: 48px;
      }
      .sm {
        font-size: 24px;
      }
    }
  }

  .contactinfo {
    ul {
      width: 1130px;
      height: 630px;
      padding: 0;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      li {
        margin-top: 10px;
        cursor: pointer;
        width: 500px;
        height: 300px;
        border: 4px solid #e8eaf2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        .top {
          width: 70px;
          height: 70px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .mid {
          font-size: 20px;

          .midtitle {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 16px;
            .midtit1 {
              font-size: 24px;
              margin-top: 32px;
              margin-bottom: 20px;
              font-weight: 500;
            }
            .midtit2 {
              width: 60px;
              height: 2px;
              background-color: #e8eaf2;
            }
          }
        }
        .bot {
          height: 30%;
          text-align: center;
          font-size: 18px;
          p {
            margin: 0;
          }
        }
      }
      li:hover {
        background-color: #e8eaf2;
        .mid {
          .midtitle {
            .midtit2 {
              background-color: #0088ea;
            }
          }
        }
      }
    }
  }
}
.product_title {
  width: 1500px;
  margin: 50px auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .product_tit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    .tit1 {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 13px;
    }
    .tit2 {
      width: 60px;
      height: 4px;
      background-color: #0088ea;
    }
  }
}
</style>